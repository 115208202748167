import store from './store/index';
import router from './router/index';
import {ajax} from './implement.js';

// let APIPATH = '//xt.ddiany.com:8886';
let APIPATH_sendMsg = '//webapi.accuratead.cn/api/SMSAgent/auth/sendMsg';
let APIPATH = '//www.sq581.com/api';
let APIPATH_SMS = '//sdkapi.sq581.com';
// let APIPATH_SMS_TEST = '//xt.ddiany.com:8886';
// let APIPATH_sendMsg = 'https://webapi.accuratead.cn/api/SMSAgent/auth/sendMsg';

function get () {
	ajax({
		url: APIPATH + '/document/get',
		type: 'GET',
		success: function (data) {
			store.commit('setDocumentCenterData', data);
		}
	});
}

function setRegister (parameter, callback) {
	ajax({
		url: APIPATH_SMS + '/user/register',
		data: JSON.stringify(parameter),
		dataType: 'json',
		contentType: 'application/json;charset=utf-8',
		type: 'POST',
		success: function (data) {
			callback(data);
		}
	});
}

function sendCode (parameter, callback) {
	ajax({
		url: APIPATH_sendMsg,
		data: JSON.stringify(parameter),
		dataType: 'json',
		contentType: 'application/json;charset=utf-8',
		type: 'POST',
		success: function () {
			callback();
		},
		error: function (msg) {
			console.log(msg);
		}
	});
}

function getMarketPrice (callback) {
	ajax({
		url: APIPATH_SMS + '/zone/market/price',
		type: 'GET',
		success: function (data) {
			callback(data);
		},
		error: function (msg) {
			console.log(msg);
		}
	});
}

function getSMSPrice (callback) {
	ajax({
		url: APIPATH_SMS + '/zone/sms/price',
		type: 'GET',
		success: function (data) {
			callback(data);
		},
		error: function (msg) {
			console.log(msg);
		}
	});
}

function getZone (callback) {
	ajax({
		url: APIPATH_SMS + '/zone/get',
		type: 'GET',
		success: function (data) {
			callback(data);
		},
		error: function (msg) {
			console.log(msg);
		}
	});
}

export {
	get,
	setRegister,
	sendCode,
	getMarketPrice,
	getSMSPrice,
	getZone
};
